export default [
  {
    name: 'dashboard',
    icon: 'nc-icon nc-bank',
    path: '/dashboard/overview'
  },
  {
    name: 'userList',
    icon: 'nc-icon nc-badge',
    path: '/users/table'
  },
  {
    name: 'faceRecognitionRecords',
    icon: 'nc-icon nc-circle-10',
    path: '/record-face/table'
  },
  {
    name: 'leave',
    icon: 'nc-icon nc-calendar-60',
    path: '/calendar'
  },
  {
    name: 'location',
    icon: 'nc-icon nc-pin-3',
    path: '/location'
  },
  {
    name: 'constructionContents',
    icon: 'nc-icon nc-cart-simple',
    path: '/soil/type'
  },
  {
    name: 'dispatchSystem',
    icon: 'nc-icon nc-time-alarm',
    children: [
      {
        name: 'dispatchSystem1',
        path: '/soil/dispatch/1',
      },
      {
        name: 'dispatchSystem2',
        path: '/soil/dispatch/2',
      },
      {
        name: 'dispatchSystem3',
        path: '/soil/dispatch/3',
      },
      {
        name: 'dispatchSystem4',
        path: '/soil/dispatch/4',
      },
      {
        name: 'dispatchSystem5',
        path: '/soil/dispatch/5',
      },
    ]
  },
  {
    name: 'earthMovingSystem',
    icon: 'nc-icon nc-bus-front-12',
    path: '/earth-moving'
  },
  {
    name: 'dispatchLog',
    icon: 'nc-icon nc-single-copy-04',
    path: '/soil-log'
  },
  // {
  //   name: '陌生人辨識紀錄',
  //   icon: 'nc-icon nc-single-02',
  //   path: '/stranger/table'
  // },
  // {
  //   name: '陌生人實名制紀錄',
  //   icon: 'nc-icon nc-single-copy-04',
  //   path: '/stranger/register/table'
  // },
  // {
  //   name: '偵測觸發紀錄',
  //   icon: 'nc-icon nc-vector',
  //   path: '/detect/table'
  // },
  {
    name: 'backgroundThreads',
    icon: 'nc-icon nc-user-run',
    path: '/queue/table'
  },
  {
    name: 'logs',
    icon: 'nc-icon nc-paper',
    path: '/log/table'
  },
  {
    name: 'importExport',
    icon: 'nc-icon nc-app',
    path: '/import-export'
  },
  {
    name: 'systemSettings',
    icon: 'nc-icon nc-settings-gear-65',
    path: '/setting'
  },
]
