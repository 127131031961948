import Vue from 'vue'

import auth from '@websanova/vue-auth/src/v2.js';
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';

Vue.use(auth, {
    plugins: {
        http: Vue.axios, // Axios
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        router: driverRouterVueRouter,
    },
    options: {
        rolesKey: 'type',
        notFoundRedirect: { name: 'user-account' },
        refreshData: { enabled: true, interval: 1 },
        stores: ["cookie", "storage"],
        cookie: {
            Path: '/',
            Domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : '', // todo: 
            Secure: true,
            Expires: 12096e5,
            SameSite: 'None',
        },
    }
});
